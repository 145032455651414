import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import SearchIcon from "../images/search.png";

import { db } from "../db";
import debounce from "./helpers/inputDebounce";
import findMatches from "./helpers/findMatches";
import ProtocolList from "../components/protocol/ProtocolList";
import StandardLayout from "../layouts/Standard";

const SearchContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  list-style: none;

  .search-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    input.clear-button {
      margin-top: 10px;
    }
  }

`

function NoResults(props) {
  if(props.hasSearched) {
    return <p>No Protocols Found</p>
  } else {
    return null;
  }
}

function Search() {
  const [hasSearched, setHasSearched] = useState(false);
  const [foundProtocols, setFoundProtocols] = useState([]);
  const searchElement = useRef();
  const search = debounce(400, getDatabaseProtocols);

  async function getDatabaseProtocols() {
    setHasSearched(true);
    const searchText = searchElement.current.value;
    if (!searchText) {
      setFoundProtocols([]);
      setHasSearched(false);
    } else {
      const protocolMatches = await db.protocols.filter(protocol => {
        return findMatches(protocol, searchText);
      }).toArray();
      
      setFoundProtocols(protocolMatches);
    }
  }

  function clearResults() {
    setFoundProtocols([]);
    setHasSearched(false);
    searchElement.current.value = "";
  }

  return (
    <StandardLayout title="Search" img={SearchIcon}>
      <SearchContainer>
        <form className="search-form" onSubmit={(e) => e.preventDefault()}>
          <input
            placeholder="Enter Query Here"
            type="text"
            ref={searchElement}
            onChange={search}
          ></input>
          <input className="clear-button" type="button" value="Clear" onClick={clearResults}/>
        </form> 
        {
          foundProtocols.length !== 0 ? 
          <ProtocolList
            className="protocol-list" 
            protocols={foundProtocols}
          /> : 
          <NoResults hasSearched={hasSearched} />
        }
      </SearchContainer>
    </StandardLayout>
  );
}

export default Search;

