import React, { useState } from 'react';

export const SettingsContext = React.createContext();

export function withSettingsContextProvider(Component) {
  return function SettingsContextProvider(props) {
    const [settings, setSettings] = useState({ adminMode: false });

    function setAdminMode(value) {
      if(typeof value !== "undefined") {
        setSettings({...settings, adminMode: value});
      } else {
        setSettings({...settings, adminMode: !settings.adminMode});
      }
    }

    // TODO Use Proper reducer/action pattern
    const settingsActions = {
      SET_ADMIN_MODE: setAdminMode
    }
  
    return (
      <SettingsContext.Provider value={{settings, settingsActions}}>
        <Component {...props} />
      </SettingsContext.Provider>
    )
  }
}
