import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default function ImageProtocol(props) {
  const { content } = props.protocol;

  let src = '';

  if(content.arrayBuffer) {
    const imageBlob = new Blob([content.arrayBuffer], {type: content.type});
    src = URL.createObjectURL(imageBlob);
  } else {
    src = `${process.env.REACT_APP_S3_BUCKET_URL}/${content}`;
  }

  return (
    <ImageWrapper>
      {content ? <img src={src} alt={`${props.protocol.title}`}/> : <p>Upload image for preview</p>}
    </ImageWrapper>
  )
}