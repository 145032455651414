import React from "react";
import styled from 'styled-components';

import Modal from "./Modal";

const ModalMessageWrapper = styled.div`
  position: relative;
  margin-top: 45vh;

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: ${props => props.backgroundColor || 'white'};
    padding: 20px 0px;
    border-radius: 15px;
  }
`

function RoundedModal(props) {
  return (
    <Modal onClickOutside={props.onClickOutside}>
      <ModalMessageWrapper backgroundColor={props.backgroundColor}>
        <div className="modal-content">
          { props.children }
        </div>
      </ModalMessageWrapper>
    </Modal>
  )
}

export default RoundedModal;
