import React, { useState } from "react";
import Modal from "../Modal";
import InfoButton from "./InfoButton";
import styled from "styled-components";

const Directions = styled.div`
  overflow-y: auto;
  background: white;
  position: absolute;
  left: 15vw;
  right: 15vw;
  top: 100px;
  bottom: 100px;

  padding: 20px;
`;

function ProtocolEditorInfo(props) {
  const [showInfo, setShowInfo] = useState(false);

  function clickHandler(event) {
    event.preventDefault();
    setShowInfo(!showInfo);
  }

  return (
    <>
      <InfoButton onClick={ clickHandler }>?</InfoButton>
      { showInfo && 
        <Modal onClickOutside={() => setShowInfo(!showInfo)}>
          <Directions>
            { props.children }
          </Directions>
        </Modal>
      }
    </>
  )
}

export default ProtocolEditorInfo;