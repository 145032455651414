import React, { useState, useEffect } from 'react';
import { useParams, Redirect, Route, useRouteMatch, Switch } from 'react-router-dom';
import styled from 'styled-components';

import StandardLayout from '../layouts/Standard';
import ProtocolList from '../components/protocol/ProtocolList';
import { db } from "../db";
import CategoryList from '../components/category/CategoryList';

const CategoryContainer = styled.div`
  margin: 0 20px;
  list-style: none;
`

function sortProtocols(a, b) {
  const protocolNumA = Number(a.protocolId.slice(2));
  const protocolNumB = Number(b.protocolId.slice(2));

  return protocolNumA - protocolNumB;
}

function CategoryProtocolList(props) {
  const [protocols, setProtocols] = useState([]);
  const { category } = props;

  useEffect(() => {
    async function getData() {
      // TODO Handle if none found.
      const protocolPrefix = category.linkData;

      const dbProtocols = await db.protocols
        .where('protocolId')
        .startsWithIgnoreCase(`${protocolPrefix}-`) // "-" is necessary to prevent "p" from matching "ph"
        .toArray();

      setProtocols(dbProtocols.sort(sortProtocols));
    }

    getData();
  }, [category]);
  
  return (
    <ProtocolList
      color={category.color}
      className="protocol-list" 
      protocols={protocols}
    />
  );
}

function CategoryCategory(props) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function getCategories() {
      const categories = await db.categories.where('parent')
        .equalsIgnoreCase(props.parent._id)
        .sortBy('slot');
      setCategories(categories);
    }

    getCategories();
  }, [props.parent._id]);

  return <CategoryList categories={ categories } />;
}

function categoryAdapter(category) {
  switch(category.linkType) {
    case "link":
      return <Redirect to={`${ category.linkData }`} />
    case "protocol_list":
      return <CategoryProtocolList category={ category } />
    case "category_list":
      return <CategoryCategory parent={ category } />
    default:
      return null;
  }
}

function Category() {
  const { url } = useRouteMatch();
  const { categoryName } = useParams();
  const [category, setCategory] = useState({});
  const [iconUrl, setIconUrl] = useState(null);

  useEffect(() => {
    async function getCategories() {
      const dbCategories = await db.categories.where('title')
        .equalsIgnoreCase(categoryName)
        .sortBy('slot');

      const category = dbCategories[0];

      if(category.icon) {
        if(category.icon.arrayBuffer) {
          const iconBlob = new Blob([category.icon.arrayBuffer], {type: category.icon.type});
          const iconObjUrl = URL.createObjectURL(iconBlob);
          setIconUrl(iconObjUrl);
        } else {
          const iconUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/${category.icon}`;
          setIconUrl(iconUrl);
        }
      }

      setCategory(category);
    }

    getCategories();
  }, [categoryName])

  return (
    <StandardLayout title={categoryName} img={ iconUrl }>
      <Switch>
        <Route path={`${url}/category/:categoryName`} component={ Category }></Route>
        <Route>
          <CategoryContainer>
            { categoryAdapter(category) }
          </CategoryContainer>
        </Route>
      </Switch>
    </StandardLayout>
  );
}

export default Category;
