import React from "react";
import styled from 'styled-components';

import ProtocolContentEditor from "./ProtocolContentEditor";
import AdminDirectionButton from "../UI/AdminDirectionButton";
import InfoButton from "../UI/InfoButton";
import ProtocolEditorDirections from "./ProtocolEditorDirections";

import withToasts from "../HOCs/withToasts";

const EditForm = styled.form`
  padding: 15px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;

  >* {
    margin-bottom: 20px;
  }

  >*:last-child {
    margin-bottom: 0;
  }

  .title-container {
    display: flex;
    justify-content: space-between;

    .protocol-title {
      flex-basis: 100%;
      margin-right: 20px;
    }

    .protocol-id {
      max-width: 40px;
    }
  }

  .protocol-body {
    height: 600px;
  }

  .controls {
    display: flex;
  }

  .direction-button {
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
  }
`;

function EditProtocol({ toastError, ...props }) {
  const { 
    protocol,
    onChange,
    onFileLoad,
    onSave,
    onReset,
    onDelete,
    ifReferenced
  } = props;

  function submitHandler(event) {
    event.preventDefault();
    onSave();
  }

  function callToast() {
    toastError(new Error(`This protocol is being referenced by other documents. 
    The Documents Referencing pane will list where ${protocol.protocolId} is referenced.`));
  }

  return (
    <EditForm onSubmit={ submitHandler }>
      <div className="title-container">
        <input 
          className="protocol-title" 
          name="title" 
          type="text"
          placeholder="Protocol Title" 
          value={ protocol.title || '' }
          onChange={ onChange } 
          required
        />
        <input 
          className="protocol-id" 
          name="protocolId" 
          type="text"
          placeholder="ab-##" 
          value={ protocol.protocolId || '' } 
          onChange={ onChange } 
          pattern="^([A-z]+)-(\d+)$"
          title="AB-123"
          required
        />
      </div>

      <ProtocolContentEditor 
        protocol={ protocol }
        onChange={ onChange }
        onFileLoad= { onFileLoad }
      />

      <label htmlFor="contentType">
        Type: 
        <select 
          name="contentType" 
          value={protocol.contentType || "markdown" } 
          onChange={ onChange }
        >
          <option value="markdown">Markdown</option>
          <option value="image">Image</option>
          <option value="pdf">PDF</option>
        </select>
      </label>

      <div className="controls">
        <button>Save</button>
        <button type="button" onClick={ onReset }>Reset</button>
        <button
            type="button"
            onClick={ onDelete }
            disabled={ifReferenced}
        >Delete</button>
        { ifReferenced && <InfoButton type="button" onClick={callToast} className="disabled-info">i</InfoButton> }
        <div className="direction-button">
          <AdminDirectionButton><ProtocolEditorDirections /></AdminDirectionButton>
        </div>
      </div>
    </EditForm>
  );
}

export default withToasts(EditProtocol)
