import React, { useState, useRef } from "react";
import styled from 'styled-components';

import triangle from '../images/triangle.png';

const AccordionSection = styled.div`
display: flex;
flex-direction: column;
align-items: stretch;
margin-bottom: 10px;

  /* Reverts list values, overriding CSS reset */
  ol, ul {
    margin: revert;
    padding: revert;
    border: revert;
    font-size: revert;
    font: revert;
    vertical-align: revert;
    list-style-type: revert;
    list-style-position: revert;
  }

  .accordion-button {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 15px 12px;
    box-shadow: 0 4px 8px 0 #CCCCCC;
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    
    border: none;
    border-left: 4px solid #C4C4C4;
    outline: none;
    transition: background-color 0.6s ease;
  }

  .accordion-button:hover,
  .active {
    background-color: #ccc;
  }

  .accordion__title {
    font-weight: bolder;
    text-align: left;
  }

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .accordion-text {
    color: #444;
    position: relative;
    z-index: 0;

    font-size: 14px;
    line-height: 20pt;
    max-height: 0;
    padding-right: 10px;

    overflow: hidden;
    transition: max-height 0.6s;
    border-right: 2px solid lightgray;
    border-bottom: 2px solid lightgray;

    line-height: 20px;
  }

  .accordion-text .section {
    padding-top: 20px;
    border-left: 4px solid #C4C4C4;
  }

  .accordion-text .section.colored {
    border-left: none;
  }

  .accordion-text.expanded {
    max-height: ${props => `${props.height + 100}px`};
  }

  .accordion-text.expanded div:last-child .section {
    border-bottom: none;
  }

  .triangle {
    width: 12px;
    height: 17px;
  }
`

function Accordion(props) {
  const content = useRef(null)
  const [active, setActive] = useState(false);
  const [scrollHeight, setHeight] = useState(0);

  function toggleAccordion() {
    const newActive = !active;
    setActive(newActive);
    setHeight(newActive ? content.current.scrollHeight : 0);
  }

  return (
    <AccordionSection className="accordion__section" height={scrollHeight}>
      <button className="accordion-button" onClick={toggleAccordion}>
        <h3 className="accordion__title">{props.title}</h3>
        <img src={triangle} alt="triangle" className={`triangle accordion__icon ${active ? "rotate" : ""}`} />
      </button>

      <div 
        ref={content}
        className={`accordion-text ${active ? "expanded" : "un-expanded"}`}
      >
        {props.children}
      </div>
    </AccordionSection>
  );
}

export default Accordion;
