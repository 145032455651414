import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";

const ProtocolContainer = styled.li`
  box-shadow: 1px 3px 4px #676767;

  transition: 0.3s;

  :hover {
    box-shadow: 2px 4px 5px #676767;
  }

  .card-container {
    display: flex;
    flex-direction: row;
    padding: 20px 40px;
    margin-bottom: 20px;
    border-left: 8px solid ${props => props.color};
  }

  .protocol-id {
    text-transform: capitalize;
    flex-grow: 1;
    font-weight: bold;
  }

  .protocol-title {
    flex-grow: 6;
    margin-left: 30px;
    font-weight: bold;
  }

  h3 {
    font-size: 1em;
  }
`

function Protocols(props) {
  const protocols = props.protocols.map(protocol => {
    return (
    <Link 
      to={`/protocol/${protocol.protocolId}`}
      key={protocol.protocolId}
      className="override-default"
    >
      <ProtocolContainer color={props.color}>
        <div className="card-container">
          <h3 className="protocol-id">{protocol.protocolId}</h3>
          <h3 className="protocol-title">{protocol.title}</h3>
        </div>
      </ProtocolContainer>
    </Link>
    )
  });

  return (
    <div className="protocols">
      {protocols}
    </div>
  );
}

export default Protocols;
