import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0px 5px 5px 0px;
  padding: 0px 15px;
  font-size: 14pt;
  border-left: 2px solid #c4c4c4;
  width: 80vw;
  max-width: 400px;

  .input-pair {
    display: flex;
    flex-direction: column;
  }

  .input-pair > * {
    margin-bottom: 5px;
  }

  .input-field {
    font-size: 12pt;
    height: 20px;
    border: none;
    border-bottom: 2px solid #c4c4c4;
  }

  .input-field:focus {
    outline: none;
    height: 20px;
    border: none;
    border-bottom: 3px solid #c4c4c4;
    padding-bottom: 1px;
  }

  .submit-button {
    margin-top: 20px;
    height: 40px;
    background-color: #c4c4c4;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    font-size: 14pt;
    font-weight: bold;
  }
`

function RegisterForm(props) {
  const [formInput, setFormInput] = useState({
    enteredToken: '',
    username: '',
    password: '',
    confirmedPassword: '',
  })

  function handleOnChange(propertyName) {
    return function(event) {
      event.target.setCustomValidity('');
      setFormInput({
        ...formInput,
        [propertyName]: event.target.value.trim(),
      })
    }
  }

  function submitForm(e) {
    e.target.reportValidity()
    e.preventDefault();

    props.attemptRegistration(formInput.username, formInput.password, formInput.enteredToken);
  }

  return (
    <Form onSubmit={submitForm}>
      <div className="input-pair">
        <label htmlFor="token" className="input-label">Enter Token:</label>
        <input 
          type="text" 
          id="token"
          name="token"
          className="input-field"
          onChange={handleOnChange('enteredToken')}
          required
        ></input>
      </div>
      <div className="input-pair">
        <label htmlFor="username" className="input-label">Enter Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          className="input-field"
          onChange={handleOnChange('username')}
          required
        ></input>
      </div>
      <div className="input-pair">
        <label htmlFor="password" className="input-label">Set Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          className="input-field"
          onChange={handleOnChange('password')}
          minLength={process.env.REACT_APP_MIN_PASSWORD_LENGTH}
          required
        ></input>
      </div>
      <div className="input-pair">
        <label htmlFor="confirm-password" className="input-label">Confirm Password:</label>
        <input
          type="password"
          id="confirm-password"
          name="confirm-password"
          className="input-field"
          onChange={handleOnChange('confirmedPassword')}
          minLength={process.env.REACT_APP_MIN_PASSWORD_LENGTH}
          pattern={formInput.password}
          onInvalid={event => event.target.setCustomValidity('Does not match password above.')}
          required
        ></input>
      </div>
      <input
        type="submit"
        value="Submit"
        className="submit-button"
      ></input>
    </Form>
  );
}

export default RegisterForm;
