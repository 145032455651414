import React from 'react';
import styled from 'styled-components';

import burger from "../../images/burger-menu.png";
import closeMenu from "../../images/close-menu.png";

const Icon = styled.button`
  height: 70%;
  width: auto;
  border-radius: 100%;
  border: 1px solid #676767;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 4px #676767;
  background: white;

  width: 40px;
  height: 40px;

  img {
    width: 80%;
    height: 60%;
  }
`;

function TapIcon(props) {
  return (
    <Icon onClick={props.menuTap} >
      <img src={props.menuTapped ? closeMenu : burger} alt="menu" />
    </Icon>
  );
}

export default TapIcon;
