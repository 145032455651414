import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { UserContext } from "../../contexts/UserContext";
import { SettingsContext } from "../../contexts/SettingsContext";

import { TouchButton } from "./UIElements";

const SettingsMenuWrapper = styled.div`
  margin-left: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  max-width: 190px;

  .admin-settings {
    margin: 30px 0;
    color: white;
  }

  button {
    width: 100%;
    height: 100%;
  }

  a {
    margin-bottom: 20px;
  }

  button.back-button {
    position: absolute;
    margin-bottom: 0;
    bottom: 30px;
    right: 100px;
    width: 170px;
    height: 40px;
  }
`;

function SettingsMenu(props) {
  const { user, userActions } = useContext(UserContext);
  const { settings, settingsActions } = useContext(SettingsContext);

  function logout() {
    userActions.LOGOUT();
    props.closeModal();
  }

  return (
    <SettingsMenuWrapper>
      <TouchButton className="back-button" onClick={props.setScreen('main')}>Main Menu</TouchButton>

      <TouchButton onClick={logout}>Logout</TouchButton>
      <TouchButton onClick={props.resetApp}>Reset Application</TouchButton>

      <div className="admin-settings">
        { user.isAdmin &&
          <label>
            Admin Mode:
            <input 
              type="checkbox" 
              checked={settings.adminMode}
              onChange={() => settingsActions.SET_ADMIN_MODE()}
            />
          </label>
        }
      </div>

      { settings.adminMode && 
        <>
          <Link to="/editUsers">
            <TouchButton onClick={props.closeModal}>Edit Users</TouchButton>
          </Link>

          <Link to="/newProtocol">
            <TouchButton onClick={props.closeModal}>New Protocol</TouchButton>
          </Link>

        </>
      }

    </SettingsMenuWrapper>
  )
}

export default SettingsMenu;