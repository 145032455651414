import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { UserContext } from "../contexts/UserContext";
import LoginForm from "../components/authentication/LoginForm";
import RegisterForm from '../components/authentication/RegisterForm';
import withToasts from "../components/HOCs/withToasts";
import DecorativeBands from "../components/authentication/DecorativeBands";

const AuthContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;

  .title {
    margin: 20px 0px;
    font-size: 18pt;  
    font-weight: bold;
    text-align: center;
    @media (min-width: 450px) {
      margin-top: 80px;
    }
  }

  .auth-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .auth-form > button {
    margin-top: 30px;
  }

  .placeholder {
    font-size: 12pt;
    text-align: center;
    line-height: 125px;
    background-color: darkblue;
    color: white;
    height: 125px;
    width: 125px;
  }

  .form-toggle {
    border-radius: 5px;
    padding: 10px 20px;
    border-width: 3px;
    color: black;
    background-color: #ffffff;
    border-color: #c4c4c4;
    margin-bottom: 10px;
    font-size: 10pt;
    font-weight: bold;
  }

  img {
    margin-top: 30px;
    height: 180px;
    @media (min-width: 450px) {
      height: 300px;
    }
    width: auto;
  }
`
// TODO Text Configuration file.
const networkErrorMessage = "Network Failure. Please try when you have an active internet connection.";

function Auth({ toastError, toastSuccess, ...props }) {
  let [showLoginForm, toggleAuthContent] = useState(true);
  const { userActions } = useContext(UserContext);

  function toggleAuthContentHandler() {
    toggleAuthContent(!showLoginForm);
  }
  
  async function handleLogin(username, password) {
    try {
      const loginResponse = await userActions.LOGIN(username, password);
      if (!loginResponse.ok) {
        const message = await loginResponse.json();
        toastError(new Error(message));
      }
    } catch(error) {
      toastError(new Error(networkErrorMessage));
    }
  }

  async function handleRegister(username, password, signupToken) {
    try {
      const registerResponse = await userActions.REGISTER(username, password, signupToken);
      if (!registerResponse.ok) {
        const message = await registerResponse.text();
        toastError(new Error(message));
      }
    } catch(error) {
      toastError(new Error(networkErrorMessage));
    }
  }

  function showAuthContent() {
    if (showLoginForm) {
      return (
        <>
          <LoginForm attemptLogin={handleLogin}/>
          <button className="form-toggle" onClick={toggleAuthContentHandler}>
            Click Here for Registration Form
          </button>
        </>
      )
    } else {
      return (
        <>
          <RegisterForm attemptRegistration={handleRegister}/>
          <button className="form-toggle" onClick={toggleAuthContentHandler}>
            Click Here for Login Form
          </button>
        </>
      )
    }
  }

  return (
    <AuthContent>
      <DecorativeBands />
      <h1 className="title">Hyper Protocol Demo</h1>
      <div className="auth-form">
        {showAuthContent()}
      </div>
    </AuthContent>
  );
}

export default withToasts(Auth);
