import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import gearIcon from "../../images/Gear-512.png"
import phoneIcon from "../../images/phone-icon.png"

import { CircleButton, TouchButton } from "./UIElements";

const MainMenuContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const PageLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  position: relative;

  bottom: -60px;
`;

const LeftButtonsBar = styled.div`
  display: inline-block;
  position: relative;
  right: 70px;
  bottom: -50px;

  button {
    /* background: white; */
    margin-top: 20px;
  }
`;

const DirectionalButtonsWrapper = styled.div`
  .direction-wrapper {
    position: relative;
    background-color: grey;
    border-radius: 70em;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 110px;

    button {
      background: white;
      font-size: 25px;
    }
  }
`;

function MainMenu(props) {
  return (
    <MainMenuContainer>
      <PageLinkContainer>
        <Link to="/">
          <TouchButton className="tap-menu-button" onClick={props.closeModal}>Main Page</TouchButton>
        </Link>

        <Link to="/search">
          <TouchButton className="tap-menu-button search" onClick={props.closeModal}>Search</TouchButton>
        </Link>
      </PageLinkContainer>

      <LeftButtonsBar>
        <CircleButton>
          <img src={gearIcon} alt="settings" onClick={props.setScreen("settings")} />
        </CircleButton>

        <Link to="/protocol/ph-00" onClick={props.closeModal}>
          <CircleButton>
            <img src={phoneIcon} alt="Phone Numbers" />
          </CircleButton>
        </Link>
      </LeftButtonsBar>

      <DirectionalButtonsWrapper>
        <div className="direction-wrapper">
          <CircleButton onClick={props.back}>{"<"}</CircleButton>
          <CircleButton onClick={props.forward}>{">"}</CircleButton>
        </div>
      </DirectionalButtonsWrapper>
    </MainMenuContainer>
  )
}

export default MainMenu;