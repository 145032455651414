import React from "react";
import styled from "styled-components";

import RoundedModal from "../RoundedModal";

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 0px 15px;

  .controls {
    display: flex;
    justify-content: center;
    border-top: 1px solid lightgray;
    margin-top: 20px;
    padding: 20px 0;
  }
`;

export default function HistoryModal(props) {
  return (
    <RoundedModal onClickOutside={props.onClose}>
      <HistoryWrapper>
        {props.children}
        <div className="controls">
          <button onClick={props.onApply}>Apply</button>
          <button onClick={props.onClose}>Cancel</button>
        </div>
      </HistoryWrapper>
    </RoundedModal>
  );
}