import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';

import Accordion from "../Accordion";
import preProcessMarkdown from "./helpers/preParseMarkdown.js"

const MDProtocolView = styled.div`
  p {
    padding-left: 10px;
    padding-bottom: 10px;
  }

  ul {
    margin: 0;
    padding-left: 30px;
    padding-bottom: 10px;
  }

  /* Removed on last item in Accordion.js */
  .colored {
    border-left: none;
    border-bottom: 1px solid gray;
  }
`;

const MarkdownWrapper = styled.div`
  border-left: 6px solid ${props => props.color};
`

// details/summary tags (TOP markdown tags for sections)
export default function MDProtocol(props) {
  const [markdownSections, setMarkdownSections] = useState([]);

  // Reversed for pop operations
  const colorBars = ([...(props.protocol.colorBars || [])]).reverse();

  useEffect(() => {
    if (props.protocol.content) {
      setMarkdownSections(preProcessMarkdown(props.protocol.content));
    }
  },[props.protocol.content]);

  function preProcessSection(markdown) {
    if(!markdown.includes('---')) {
      return <Markdown className="section">{ markdown }</Markdown>;
    } else {
      const coloredSections = markdown.split("---");
      return coloredSections.map((subSection, index) => {

        // Currently no better way exists to get these colors here
        const color = colorBars.pop() || "lightgray";

        return (
          <MarkdownWrapper color={color} key={index}>
            <Markdown className={`section colored`}>
              { subSection }
            </Markdown>
          </MarkdownWrapper>
        )
      });
    }
  }

  function renderMarkdown() {
    return markdownSections.map((section, index) => {
      if(!section) {
        return null;
      } else if(!section.title) {
        return <div key={section.title + index}>{ preProcessSection(section.markdown) }</div>
      } else {
        return <Accordion
          title={section.title}
          key={section.title + index}
        >
          { preProcessSection(section.markdown) }
        </Accordion>
      }
    });
  }

  return (
    <MDProtocolView>
      { renderMarkdown() }
    </MDProtocolView>
  );
}
