import styled from "styled-components";

export const CircleButton = styled.button`
  :hover {
    background: white;
    border: 2px solid grey;
  }

  background: white;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid black;

  img {
    height: 80%;
    width: 80%;
  }
`;

export const TouchButton = styled.button`
  :hover {
    background: white;
    border: 2px solid grey;
  }

  background: white;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 10px;
  width: 200px;
  min-height: 40px;

  font-size: 1.0em;
`;